import React, {useEffect, useState} from 'react';
import {graphql} from 'gatsby';

import {useLocale} from '@app/hooks';

import {
  BeautyCard,
  Card,
  CardTitle,
  FashionCard,
  Layout,
  NewsCard,
  Post,
  Seo,
  VideoCard,
} from '@app/components';
import {
  collection,
  where,
  limit,
  getDocs,
  query,
  orderBy,
} from 'firebase/firestore';
import {db} from '../../firebase';

type HomePropTypes = {
  data: {
    headline: PostPropType;
    carousel: PostPropType;
    authorLastPost: PostPropType;
    centerPost: PostPropType;
    video: PostPropType;
    agendaTabs: PostPropType;
    plasticSurgeryTabs: PostPropType;
    beautyTabs: PostPropType;
    fashionTabs: PostPropType;
    lifeTabs: PostPropType;
    healthTabs: PostPropType;
    tripTabs: PostPropType;
  };
  pageContext: {
    language: string;
  };
};

type PostPropType = {
  nodes: {
    excerpt: string;
    frontmatter: {
      url: string;
      title: string;
      author: string;
      date: string;
      cover: any;
      imageUrl: string;
    };
  }[];
};

const Home: React.FC<HomePropTypes> = ({data, pageContext}) => {
  const [isRender, setIsRender] = useState(false);
  const [authorItems, setAuthorItems] = useState(data.authorLastPost.nodes);
  const [carouselItems, setCarouselItems] = useState(data.headline.nodes);
  const [agendaItems, setAgendaItems] = useState(data.agendaTabs.nodes);
  const [sbbTvItems, setSbbTvItems] = useState(data.video.nodes);
  const [beautyItems, setBeautyItems] = useState(data.beautyTabs.nodes);
  const [fashionItems, setFashionItems] = useState(data.fashionTabs.nodes);
  const [lifeItems, setLifeItems] = useState(data.lifeTabs.nodes);
  const [plasticSurgeryItems, setPlasticSurgeryItems] = useState(
    data.plasticSurgeryTabs.nodes,
  );
  const [tripItems, setTripItems] = useState(data.tripTabs.nodes);
  const SCOPE_OPTIONS = {
    scope: 'pages.Index',
  };
  const {t} = useLocale();

  useEffect(() => {
    getCarouselData();
    getAgendaData();
    getSbbTvData();
    //getBeautyData();
    getCategoriesData(
      t('actions.beautyTab', SCOPE_OPTIONS),
      beautyItems,
      setBeautyItems,
    );
    getCategoriesData(
      t('actions.fashionTab', SCOPE_OPTIONS),
      fashionItems,
      setFashionItems,
    );
    getCategoriesData(
      t('actions.lifeTab', SCOPE_OPTIONS),
      lifeItems,
      setLifeItems,
    );
    getCategoriesData(
      t('actions.plasticSurgeryTab', SCOPE_OPTIONS),
      plasticSurgeryItems,
      setPlasticSurgeryItems,
    );
    getCategoriesData(
      t('actions.tripTab', SCOPE_OPTIONS),
      tripItems,
      setTripItems,
    );
    getAuthorsData();
  }, []);

  const getCarouselData = () => {
    const mappingData: any = [];
    const unSub = async () => {
      const q = query(
        collection(db, 'posts'),
        where('language', '==', pageContext.language),
        orderBy('createAt', 'desc'),
        limit(15),
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        mappingData.push({
          excerpt: doc.data().article,
          frontmatter: {
            title: doc.data().title,
            author: doc.data().author,
            date: new Date(doc.data().createAt.seconds * 1000).toISOString(),
            imageUrl: doc.data().imageUrl,
          },
        });
      });

      const showingPosts = [...mappingData, ...data.headline.nodes].slice(
        0,
        15,
      );

      showingPosts.sort(function (a, b) {
        var keyA = new Date(a.frontmatter.date),
          keyB = new Date(b.frontmatter.date);
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });

      setCarouselItems(showingPosts);
      setIsRender(true);
      //setIsLoad(true);
    };

    unSub();
  };

  const getAuthorsData = () => {
    const mappingData: any = [];
    const unSub = async () => {
      const q = query(
        collection(db, 'posts'),
        where('language', '==', pageContext.language),
        orderBy('author'),
        where('author', '!=', 'ClinicSBB'),
        orderBy('createAt', 'desc'),
        limit(15),
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        mappingData.push({
          excerpt: doc.data().article,
          frontmatter: {
            title: doc.data().title,
            author: doc.data().author,
            date: new Date(doc.data().createAt.seconds * 1000).toISOString(),
            imageUrl: doc.data().imageUrl,
          },
        });
      });

      const showingPosts = [...mappingData, ...authorItems].slice(0, 15);

      showingPosts.sort(function (a, b) {
        var keyA = new Date(a.frontmatter.date),
          keyB = new Date(b.frontmatter.date);
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });

      setAuthorItems(showingPosts);
      //setIsLoad(true);
    };

    unSub();
  };

  const getAgendaData = () => {
    const mappingData: any = [];
    const unSub = async () => {
      const q = query(
        collection(db, 'posts'),
        where('language', '==', pageContext.language),
        where(
          'categories',
          'array-contains',
          t('actions.agenda', SCOPE_OPTIONS),
        ),
        orderBy('createAt', 'desc'),
        limit(15),
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        mappingData.push({
          excerpt: doc.data().article,
          frontmatter: {
            title: doc.data().title,
            author: doc.data().author,
            date: new Date(doc.data().createAt.seconds * 1000).toISOString(),
            imageUrl: doc.data().imageUrl,
          },
        });
      });

      const showingPosts = [...mappingData, ...agendaItems].slice(0, 15);

      showingPosts.sort(function (a, b) {
        var keyA = new Date(a.frontmatter.date),
          keyB = new Date(b.frontmatter.date);
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });

      setAgendaItems(showingPosts);
      //setIsLoad(true);
    };

    unSub();
  };

  const getSbbTvData = () => {
    const mappingData: any = [];
    const unSub = async () => {
      const q = query(
        collection(db, 'posts'),
        where('language', '==', pageContext.language),
        where('categories', 'array-contains', 'Youtube'),
        orderBy('createAt', 'desc'),
        limit(15),
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        mappingData.push({
          excerpt: doc.data().article,
          frontmatter: {
            title: doc.data().title,
            author: doc.data().author,
            date: new Date(doc.data().createAt.seconds * 1000).toISOString(),
            imageUrl: doc.data().imageUrl,
            url: doc.data().url,
          },
        });
      });

      const showingPosts = [...mappingData, ...sbbTvItems].slice(0, 3);

      showingPosts.sort(function (a, b) {
        var keyA = new Date(a.frontmatter.date),
          keyB = new Date(b.frontmatter.date);
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });

      setSbbTvItems(showingPosts);
      //setIsLoad(true);
    };

    unSub();
  };

  const getCategoriesData = (category: string, state: any, stateFunc: any) => {
    const mappingData: any = [];
    const unSub = async () => {
      const q = query(
        collection(db, 'posts'),
        where('language', '==', pageContext.language),
        where('categories', 'array-contains', category),
        orderBy('createAt', 'desc'),
        limit(3),
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        mappingData.push({
          excerpt: doc.data().article,
          frontmatter: {
            title: doc.data().title,
            author: doc.data().author,
            date: new Date(doc.data().createAt.seconds * 1000).toISOString(),
            imageUrl: doc.data().imageUrl,
          },
        });
      });

      const showingPosts = [...mappingData, ...state].slice(0, 3);

      showingPosts.sort(function (a, b) {
        var keyA = new Date(a.frontmatter.date),
          keyB = new Date(b.frontmatter.date);
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });

      stateFunc(showingPosts);
      //setIsLoad(true);
    };

    unSub();
  };

  return (
    <Layout boxed>
      <Seo title={t('labels.metaTitle', SCOPE_OPTIONS)} />
      <div className={`${isRender ? 'content-block' : 'content-none'}`}>
        <Post.Carousel posts={carouselItems} />

        <div className="px-6 py-4 layout__inner">
          <div className="grid lg:grid-cols-12 gap-8 mb-8">
            <div className="lg:col-span-7">
              <BeautyCard
                title={t('actions.agenda', SCOPE_OPTIONS)}
                titleColor="yellow"
                disableTitle={true}
                single={true}
                beautyCardList={[
                  {
                    title: '',
                    posts: [agendaItems[0]],
                  },
                ]}
              />
            </div>
            <div className="lg:col-span-5 mb-10 news-card-container mt-10">
              <div className="news-card-title font-bold text-2xl p-4">
                {t('actions.vox', SCOPE_OPTIONS)}
              </div>

              <div className="news-cards">
                {carouselItems.map((post, index) => (
                  <NewsCard
                    key={index}
                    title={post.frontmatter.title}
                    description={post.excerpt}
                    date={post.frontmatter.date}
                    category={t('actions.vox', SCOPE_OPTIONS)}
                    imageUrl={
                      post.frontmatter.cover?.publicURL ||
                      post.frontmatter?.imageUrl
                    }
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="pt-4 pb-8 lg:pt-8">
            <CardTitle title="SBB TV" titleColor="black" />
            <div className="grid lg:grid-cols-12 gap-8 mb-8">
              {sbbTvItems.map((video, index) => (
                <div className="lg:col-span-4">
                  <VideoCard
                    key={index}
                    title={video.frontmatter.title}
                    description={video.excerpt}
                    author={video.frontmatter.author}
                    date={video.frontmatter.date}
                    url={video.frontmatter.url}
                    cover={video.frontmatter?.cover?.childImageSharp.fluid}
                    imageUrl={video.frontmatter.imageUrl}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="grid lg:grid-cols-12 gap-8 mb-8">
            <div className="lg:col-span-5">
              <div className="news-card-title font-bold text-2xl p-4">
                {t('actions.authors', SCOPE_OPTIONS)}
              </div>

              <div className="news-cards">
                {authorItems.map((author, index) => (
                  <NewsCard
                    key={index}
                    title={author.frontmatter.title}
                    description={author.excerpt}
                    date={author.frontmatter.date}
                    category={t('actions.authors', SCOPE_OPTIONS)}
                    author={author.frontmatter.author}
                    imageUrl={
                      author.frontmatter.cover?.publicURL ||
                      author.frontmatter?.imageUrl
                    }
                  />
                ))}
              </div>
            </div>
            <div className="lg:col-span-7">
              <Card
                title={data.centerPost.nodes[0].frontmatter.title}
                description={data.centerPost.nodes[0].excerpt}
                author={data.centerPost.nodes[0].frontmatter.author}
                date={data.centerPost.nodes[0].frontmatter.date}
                cover={
                  data.centerPost.nodes[0].frontmatter.cover?.childImageSharp
                    .fluid
                }
              />
            </div>
          </div>

          <div className="pt-8 pb-8">
            <BeautyCard
              title={t('actions.beautyTab', SCOPE_OPTIONS)}
              titleColor="red"
              beautyCardList={[
                {
                  title: '',
                  posts: beautyItems,
                },
              ]}
            />
          </div>

          <div className="pt-8 pb-8">
            <FashionCard
              title={t('actions.fashionTab', SCOPE_OPTIONS)}
              titleColor="pink"
              fashionCardList={[
                {
                  title: '',
                  posts: fashionItems,
                },
              ]}
            />
          </div>

          <div className="pt-8 pb-8">
            <BeautyCard
              title={t('actions.lifeTab', SCOPE_OPTIONS)}
              titleColor="green"
              beautyCardList={[
                {
                  title: '',
                  posts: lifeItems,
                },
              ]}
            />
          </div>

          <div className="pt-8 pb-8">
            <FashionCard
              title={t('actions.plasticSurgeryTab', SCOPE_OPTIONS)}
              titleColor="purple"
              fashionCardList={[
                {
                  title: '',
                  posts: plasticSurgeryItems,
                },
              ]}
            />
          </div>

          <div className="pt-8 pb-8">
            <BeautyCard
              title={t('actions.agenda', SCOPE_OPTIONS)}
              titleColor="yellow"
              beautyCardList={[
                {
                  title: '',
                  posts: agendaItems.slice(0, 3),
                },
              ]}
            />
          </div>

          <div className="pt-8 pb-8">
            <FashionCard
              title={t('actions.tripTab', SCOPE_OPTIONS)}
              titleColor="brown"
              fashionCardList={[
                {
                  title: '',
                  posts: tripItems,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;

export const pageQuery = graphql`
  query ($language: String!) {
    headline: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {
          language: {eq: $language}
          categories: {
            nin: ["Dergi", "Magazine", "Ajanda", "Agenda", "Youtube"]
          }
        }
      }
      limit: 15
    ) {
      nodes {
        excerpt(pruneLength: 280)
        frontmatter {
          title
          author
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            publicURL
            childImageSharp {
              resize(width: 150, height: 150) {
                src
              }
            }
          }
        }
      }
    }

    authorLastPost: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {
          language: {eq: $language}
          categories: {in: ["Yazar", "Author"]}
        }
      }
      limit: 12
    ) {
      nodes {
        excerpt(pruneLength: 80)
        frontmatter {
          title
          author
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    centerPost: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {
          language: {eq: $language}
          categories: {nin: ["Dergi", "Magazine"]}
        }
      }
      skip: 4
      limit: 1
    ) {
      nodes {
        excerpt(pruneLength: 280)
        frontmatter {
          title
          author
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    video: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {language: {eq: $language}, categories: {eq: "Youtube"}}
      }
      limit: 3
    ) {
      nodes {
        excerpt(pruneLength: 130)
        frontmatter {
          title
          author
          date(formatString: "MMMM D, YYYY", locale: $language)
          url
          cover {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    agendaTabs: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {
          language: {eq: $language}
          categories: {in: ["Ajanda", "Agenda"]}
        }
      }
      limit: 3
    ) {
      nodes {
        excerpt(pruneLength: 400)
        frontmatter {
          title
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              resize(width: 910, height: 600) {
                src
              }
            }
          }
        }
      }
    }

    plasticSurgeryTabs: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {
          language: {eq: $language}
          categories: {in: ["Plastik Cerrahi", "Plastic Surgery"]}
        }
      }
      limit: 3
    ) {
      nodes {
        excerpt(pruneLength: 400)
        frontmatter {
          title
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              resize(width: 910, height: 600) {
                src
              }
            }
          }
        }
      }
    }

    beautyTabs: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {
          language: {eq: $language}
          categories: {in: ["Güzellik", "Beauty"]}
        }
      }
      limit: 3
    ) {
      nodes {
        excerpt(pruneLength: 400)
        frontmatter {
          title
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              resize(width: 910, height: 600) {
                src
              }
            }
          }
        }
      }
    }

    fashionTabs: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {
          language: {eq: $language}
          categories: {in: ["Moda", "Fashion"]}
        }
      }
      limit: 3
    ) {
      nodes {
        excerpt(pruneLength: 400)
        frontmatter {
          title
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              resize(width: 910, height: 600) {
                src
              }
            }
          }
        }
      }
    }

    lifeTabs: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {
          language: {eq: $language}
          categories: {in: ["Yaşam", "Life"]}
        }
      }
      limit: 3
    ) {
      nodes {
        excerpt(pruneLength: 400)
        frontmatter {
          title
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              resize(width: 910, height: 600) {
                src
              }
            }
          }
        }
      }
    }

    healthTabs: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {
          language: {eq: $language}
          categories: {in: ["Sağlık", "Health"]}
        }
      }
      limit: 3
    ) {
      nodes {
        excerpt(pruneLength: 400)
        frontmatter {
          title
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              resize(width: 910, height: 600) {
                src
              }
            }
          }
        }
      }
    }

    tripTabs: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {
          language: {eq: $language}
          categories: {in: ["Gezi", "Trip"]}
        }
      }
      limit: 3
    ) {
      nodes {
        excerpt(pruneLength: 400)
        frontmatter {
          title
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              resize(width: 910, height: 600) {
                src
              }
            }
          }
        }
      }
    }

    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
